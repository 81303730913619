import {config} from '~/adminstrap'
const {Vue, EventBus} = config

const addiesaas = window.addiesaas = window.addiesaas || {}

import addiesaasConfig from '../config'
import { rollbarVue } from '../rollbar'

import vuestrap from './vuestrap'

import {init} from './axios'

const admin = window.addiesaas.spa = window.addiesaas.spa || {
  ...config,
  instances: [],
  axiosInit: {init},
  async init(el, options) {
    const strap = {...vuestrap, ...options || {}, el}

    EventBus.$emit('addiesaas.app.beforeInit', el, addiesaas, strap)

    const instance = new Vue(strap);
    window.addiesaas.spa.instances.push(instance)

    EventBus.$emit('addiesaas.app.init', instance, addiesaas, strap)

    return instance
  },
  initSync(options) {
    const strap = {...vuestrap, ...options || {}}

    EventBus.$emit('addiesaas.app.beforeInit', options, addiesaas, strap)

    const instance = new Vue(strap)
    window.addiesaas.spa.instances.push(strap)

    EventBus.$emit('addiesaas.app.init', instance, addiesaas, strap)

    return instance
  }
}

rollbarVue(Vue)

init()

EventBus.$emit('addiesaas.beforeInit', addiesaas)

const readyCallbacks = addiesaas.ready && addiesaas.ready.callbacks || []
addiesaas.ready = callbacks => {
  if (!callbacks && readyCallbacks.length) {
    callbacks = readyCallbacks
  } else if (callbacks) {
    //
  }

  if (_.isFunction(callbacks)) {
    return callbacks.call(addiesaas)
  } else if (_.isArray(callbacks)) {
    let callback = null
    while( (callback = callbacks.shift()) !== undefined ) {
      addiesaas.ready(callback)
    }
  }
}

addiesaas.ready()

export default admin
