import Rollbar from 'rollbar'

const addiesaas = window.addiesaas = window.addiesaas || {}

const clientAccessToken = addiesaas.config?.rollbar?.token
const itemsPerMinute = addiesaas.config?.rollbar?.items_per_minute
const environment = addiesaas.config?.env

const rollbar = new Rollbar({
    enabled: Boolean(clientAccessToken),
    accessToken: clientAccessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment,
    },
    itemsPerMinute: itemsPerMinute || undefined,
})

export const rollbarVue = (Vue) => {
    Vue.prototype.$rollbar = rollbar

    const errorHandler = Vue.config.errorHandler

    Vue.config.errorHandler = (...args) => {
        const [err, vm, info] = args

        vm.$rollbar.error(err, {
            component: vm.$options.name || '(anonymous component)',
            info,
        })

        if (errorHandler) {
            errorHandler(...args)
        } else {
            console.error(err)
        }
    }
}
