/*global _, addiesaas, $ */
import {DESIGNER_EVENTS as E, SILENT_STORE_OPTIONS as SILENT} from './constants'
import {getBuilderComponentEmitter} from './utils'
import {create as createVue} from '../../lib/vue'

const storePath = '$data.builders.vueComponents'

const exportDefault = {}

export const create = exportDefault.create = (componentName, options = {}, config) => {
  config = config || {}
  const {designerMode = true} = config
  const componentOptions = _.merge({
    props: {
      designerMode
    }
  }, options || {})

  callVue({}, E.VUE_CREATE, {componentName, componentOptions, ...config})
  const instance = createVue(componentName, componentOptions)

  if (!designerMode) {
    return instance
  }

  const data = {}
  if (instance) {
    init(instance, data, config)
    callVue(instance, E.VUE_CREATED, {data, ...config})
  }
  return data
}

export const recreate = exportDefault.recreate = function (component) {
  const isInstance = component.get('vueType') === 'instance'
  if (isInstance) {
    return render({component})
  }
  let editor = component.em.getEditor()
  if (!editor) {
    const editorGetter = component.get('getEditor')
    if (_.isFunction(editorGetter)) {
      editor = editorGetter()
      if (editor) {
        component.em.set('Editor', editor)
      }
    }
  }
  const componentName = component.get('componentName')
  const props = component.getVueProps && component.getVueProps() || {}
  const {instance, ...vueOptionsDef} = create(componentName, {props}, {component, editor, recreate: true})
  callVue(instance, E.RECREATED, {component, editor, data: vueOptionsDef})
  addVueInstanceToChildren(component, instance)

  component.loadVueOptions(vueOptionsDef)
}

const addVueInstanceToChildren = (component, instance) => {
  const getVueInstance = component.get('getVueInstance')
  const children = [...component.findType('vue-prop') || [], ...component.findType('vue-slot') || []]
  _.forOwn(children, c => {
    if (!c.get('getVueInstance')) {
      c.set('getVueInstance', getVueInstance, SILENT)
    }
  })
}

export const render = exportDefault.render = ({editor, el, component, model}) => {
  component = component || model
  editor = editor || component.em.getEditor()
  const componentName = component.get('componentName')
  const props = component.getVueProps && component.getVueProps({}, {build: false, skipId: true, skipEvent: true}) || {}
  const {instance, ...vueOptionsDef} = create(componentName, {props}, {component, editor, render: true})
  component.loadVueOptions(vueOptionsDef, true)

  const eventParams = {component, editor, vm: instance, vueOptionsDef}
  callVue(instance, E.VUE_RENDER_INIT, eventParams)

  el = el || component.getEl()
  const mount = (elm) => {
    window.setTimeout(() => {
      elm = elm || el
      elm.append(instance.$el)
      eventParams.el = elm
      callVue(instance, E.VUE_RENDER_MOUNTED, eventParams)
    }, 0)
  }
  if (el) {
    mount()
  } else {
    const cid = component.getId()
    editor.on('component:mount', model => {
      const mid = model.getId()
      if (cid === mid) {
        mount(model.getEl())
      }
    })
  }
  //
  // const $el = $(el)
  // const hasChildren = el.children && el.children.length
  // const hasRawComponent = $el.find(componentName).length
  // if (!hasChildren || hasRawComponent) {
  // }
}

export const set = exportDefault.set = (instance) => {
  const uid = instance._uid
  addiesaas.$fn.findOrCreate(storePath, {})
  _.set(addiesaas, `${storePath}.${uid}`, instance)
  return instance
}

export const get = exportDefault.get = (uid) => {
  return _.get(addiesaas, `${storePath}.${uid}`)
}

export const destroy = exportDefault.destroy = (uid) => {
  if (_.isObject(uid)) {
    uid = uid._uid
  }
  return _.unset(addiesaas, `${storePath}.${uid}`)
}

export const instances = exportDefault.instances = () => {
  return _.get(addiesaas, `${storePath}`, {})
}

export const init = exportDefault.init = (instance, data, {component, recreate, render}) => {
  data.instance = set(instance)
  const properties = data.properties = (data.properties || {})

  // Bypass page wrapper component/instance of UI/WEB/Resources/Assets/js/components/pages/*
  // The real instance is the child of the wrapper instance
  const targetInstance = instance.pageWrapperComponent ? (instance.$children[0] || instance) : instance

  const getVue = properties.getVueInstance = () => targetInstance
  const destroyVue = properties.destroyVueInstance = () => {
    destroy(instance)
    instance = null
    data.instance = null
    properties.getVueInstance = () => ({})
  }
  if (recreate || render) {
    component.set('getVueInstance', getVue, SILENT)
    component.set('destroyVueInstance', destroyVue, SILENT)
  }
}

export const callVue = exportDefault.callVue = (instance, fn, ...params) => {
  instance = instance || {}
  const globalEmit = getBuilderComponentEmitter(instance)
  const emit = (name, ...params) => {
    if (instance.$emit) {
      instance.$emit(name, ...params)
    }
    globalEmit(name, ...params)
  }

  emit(`${fn}:before`, ...params)

  let $fn = '$_' + fn
  if (instance[$fn]) {
    instance[$fn](...params)
  }

  if (instance[fn]) {
    instance[fn](...params)
  }
  emit(fn, ...params)

  let fn$ = fn + '$_'
  if (instance[fn$]) {
    instance[fn$](...params)
  }

  emit(`${fn}:after`, ...params)
}

export const callOnReady = exportDefault.callOnReady = (instance, fn, ...args) => {
  const isDesigner = instance.designerMode && instance.isDesignerMode
  if (!isDesigner) {
    return
  }

  const getArgs = (component, args) => {
    const editor = component.em.getEditor()
    const arg = args[0] || (args[0] = {})
    Object.assign(arg, {component, editor})
    return args
  }

  const notify = (component, unwatch) => {
    if (unwatch) {
      unwatch()
    }
    if (_.isFunction(fn)) {
      component.onReady(() => {
        fn.call(component, ...args)
      })
    } else {
      component.onReady(() => {
        callVue(instance, fn, ...getArgs(component, args))
      })

    }
  }

  instance.$nextTick(() => {
    const watchedComponent = instance.designerObject.component
    if (watchedComponent) {
      notify(watchedComponent)
    } else {
      const unwatch = instance.$watch(
        () => instance.designerObject.component,
        (component) => {
          if (component) {
            notify(component, unwatch)
          }
        },
        {
          immediate: true
        }
      )
    }
  })
}

export default exportDefault
